<template>
    <user-dashboard-layout>
        <v-row>
            <v-col cols="12" sm="12" md="6" lg="4" xl="3" v-for="favorite in favorites" :key="favorite.id">
                <component :is="favoriteComponent(favorite.favoritable_type)"
                           v-bind="favoriteComponentProps(favorite)"/>
            </v-col>
        </v-row>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "@/layouts/UserDashboardLayout";
import ProjectCard from "@/components/cards/ProjectCard";

export default {
    name: "Favorites",
    components: {ProjectCard, UserDashboardLayout},
    computed: {
        favorites() {
            return this.$auth.user()?.favorites ?? [];
        }
    },
    methods: {
        favoriteComponent(type) {
            switch (type) {
                case 'App\\Models\\Project':
                    return 'project-card'
                default:
                    return 'div';
            }
        },
        favoriteComponentProps(favorite) {
            switch (favorite.favoritable_type) {
                case 'App\\Models\\Project':
                    return {
                        'project': favorite.favoritable
                    }
                default:
                    return {};
            }
        },
    }
}
</script>

<style scoped>

</style>
